<script>
import ApiService from '../../service/api'
const service = new ApiService()
export default {
  data() {
    return {
      categoryData: [],
      form: {},
      showModal: true
    }
  },
  created() {
    this.loadCategory()
  },
  mounted() {
    this.loadCategory()
  },
  methods: {
    loadCategory() {
      try {
        service.serviceGetCategory().then((response) => {
          switch (response.status) {
            case 200:
              this.categoryData = response.results
              // console.table(this.categoryData)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    createCategory() {
      try {
        service.servicePostCategory(this.form).then((res) => {
          switch (res.status) {
            case 200:
              console.log(res.message)
              this.showModal = false
              this.loadCategory()
              break
            default:
              console.log(res)
              break
          }
          location.reload()
        })
      } catch (error) {
        console.log(error)
      }
    },

    updateCategory() {
      try {
        service.serviceUpdateCategory(this.form).then((res) => {
          switch (res.status) {
            case 200:
              this.showModal = false
              location.reload()
              break
            default:
              console.log(res)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    deleteCategory() {
      try {
        service.serviceDeleteCategory(this.form.idCategory).then((res) => {
          switch (res.status) {
            case 200:
              this.showModal = false
              this.loadCategory()
              break
            default:
              console.log(res)
              break
          }
          location.reload()
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
