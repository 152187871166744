import firebase from 'firebase/compat/app'
import 'firebase/compat/messaging'

var firebaseConfig = {
  apiKey: 'AIzaSyB6saYn1VRzLXiWJtWpe8mNfKi97Oo-i_g',
  authDomain: 'kalenderkonten-4d496.firebaseapp.com',
  projectId: 'kalenderkonten-4d496',
  storageBucket: 'kalenderkonten-4d496.appspot.com',
  messagingSenderId: '605857654041',
  appId: '1:605857654041:web:3aa526c603a2b8bd709188',
  measurementId: 'G-D3QTGKJPSN'
}
firebase.initializeApp(firebaseConfig)
export default firebase.messaging()
