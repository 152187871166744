import Toastify from 'toastify-js'

export default class Toast {
  gagal() {
    Toastify({
      node: cash('#error-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }

  berhasil() {
    Toastify({
      node: cash('#success-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }

  alert() {
    Toastify({
      node: cash('#alert-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }

  messaging() {
    Toastify({
      node: cash('#notification-box')
        .clone()
        .removeClass('hidden')[0],
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: 'top',
      position: 'right',
      stopOnFocus: true
    }).showToast()
  }
}
