import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import firebaseMessaging from './fcm'
// SASS Theme
import './assets/sass/app.scss'
// firebase.initializeApp({
//   apiKey: 'AIzaSyB6saYn1VRzLXiWJtWpe8mNfKi97Oo-i_g',
//   authDomain: 'kalenderkonten-4d496.firebaseapp.com',
//   projectId: 'kalenderkonten-4d496',
//   storageBucket: 'kalenderkonten-4d496.appspot.com',
//   messagingSenderId: '605857654041',
//   appId: '1:605857654041:web:3aa526c603a2b8bd709188',
//   measurementId: 'G-D3QTGKJPSN'
// })

// navigator.serviceWorker
//   .register('firebase-messaging-sw.js')
//   .then((registration) => {
//     const messaging = firebase.messaging()
//     messaging.useServiceWorker(registration)
//   })
//   .catch((err) => {
//     console.log(err)
//   })

const app = createApp(App)
  .use(store)
  .use(router)
app.config.globalProperties.$messaging = firebaseMessaging
globalComponents(app)
utils(app)

app.mount('#app')
