<script>
import { defineComponent, ref } from 'vue'
import ApiService from '../../service/api'
const service = new ApiService()

export default defineComponent({
  setup() {
    const date = ref('')
    const editorData = ref('<p>Content of the editor.</p>')

    return {
      date,
      editorData
    }
  },
  data: () => ({
    loading: false,
    data: [],
    template: ''
  }),
  created() {
    this.getEmail()
  },
  methods: {
    getEmail() {
      try {
        service.serviceGetEmail().then((response) => {
          switch (response.status) {
            case 200:
              this.data = response.results
              this.template = response.results[0].emailTemplate
              console.log(this.template)
              // console.log(response.results[0].emailTemplate)
              // console.log(this.data)
              break
            default:
              console.log(response)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    createEmail() {
      try {
        service.servicePostEmail().then((response) => {
          console.log(response)
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
})
</script>
