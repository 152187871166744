<template>
  <div>
    <h2 class="mt-10 text-lg font-medium intro-y">Voucher</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="flex flex-wrap items-center col-span-12 mt-2 intro-y sm:flex-nowrap"
      >
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#added-voucher-modal"
        >
          <button class="mr-2 shadow-md btn btn-primary">
            Add New Voucher
          </button></a
        >

        <div class="dropdown">
          <button
            class="px-2 text-gray-700 dropdown-toggle btn box dark:text-gray-300"
            aria-expanded="false"
          >
            <span class="flex items-center justify-center w-5 h-5">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="w-40 dropdown-menu">
            <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
              >
                <UsersIcon class="w-4 h-4 mr-2" /> Add Group
              </a>
              <a
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
              >
                <MessageCircleIcon class="w-4 h-4 mr-2" /> Send Message
              </a>
            </div>
          </div>
        </div>
        <div class="hidden mx-auto text-gray-600 md:block">
          Showing 1 to 10 of 150 entries
        </div>
        <div class="w-full mt-3 sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
          <div class="relative w-56 text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="w-56 pr-10 form-control box placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Category -->
      <div
        v-for="(voucher, index) in voucher"
        :key="index"
        class="col-span-12 intro-y md:col-span-6"
      >
        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div class="report-box zoom-in">
            <div class="p-5 box">
              <div class="flex">
                <TagIcon class="report-box__icon text-theme-10" />
              </div>
              <div class="mt-6 text-3xl font-medium leading-8">
                {{ voucher.namaKupon }}
              </div>
              <div class="mt-1 text-base text-gray-600">
                {{ voucher.deskripsi }}
              </div>
              <!--  -->
              <div class="p-5 box">
                <div class="mt-6 text-3xl font-medium leading-8 text-center">
                  {{ voucher.potongan_spesial }}%
                </div>
                <div class="mt-1 text-base text-center text-gray-600">
                  kode voucher : {{ voucher.kodeKupon }}
                </div>
                <div class="mt-1 text-base text-center">
                  {{ voucher.valid_from }} - {{ voucher.valid_to }}
                </div>
              </div>
              <!--  -->
              <div class="flex">
                <div class="absolute top-0 right-0 mt-3 mr-5 dropdown">
                  <a
                    class="block w-5 h-5 dropdown-toggle"
                    href="javascript:;"
                    aria-expanded="false"
                  >
                    <MoreVerticalIcon
                      class="w-5 h-5 text-gray-600 dark:text-gray-300"
                    />
                  </a>
                  <div class="w-40 dropdown-menu">
                    <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                      <a
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#update-voucher-modal"
                        v-on:click="form = voucher"
                      >
                        <Edit2Icon class="w-4 h-4 mr-2" /> Edit
                      </a>
                      <a
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#delete-confirmation-modal"
                        v-on:click="form = voucher"
                      >
                        <TrashIcon class="w-4 h-4 mr-2" />
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="mt-6 text-3xl font-medium leading-8">
                {{ cat.category }}
              </div>
              <div class="mt-1 text-base text-gray-600">Item Sales</div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- END: Category -->
      <div class="absolute top-0 right-0 mt-3 mr-5 dropdown">
        <a
          class="block w-5 h-5 dropdown-toggle"
          href="javascript:;"
          aria-expanded="false"
        >
          <MoreHorizontalIcon
            class="w-5 h-5 text-gray-600 dark:text-gray-300"
          />
        </a>
        <div class="w-40 dropdown-menu">
          <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
            <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
            >
              <Edit2Icon class="w-4 h-4 mr-2" /> Edit
            </a>
            <a
              href=""
              class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
            >
              <TrashIcon class="w-4 h-4 mr-2" /> Delete
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: added Confirmation Modal -->
    <div
      id="added-voucher-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      v-if="modalShow"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-left">
              <div class="text-3xl">Add Voucher</div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input Title"
                  v-model="form.title"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input Code Voucher"
                  v-model="form.code"
                />
              </div>
                            <div class="mt-6 preview">
                <label>Valid Date From</label>
                <div class="relative">
                  <div
                    class="absolute flex items-center justify-center w-10 h-full text-gray-600 bg-gray-100 border rounded-l dark:bg-dark-1 dark:border-dark-4"
                  >
                    <CalendarIcon class="w-4 h-4" />
                  </div>
                  <Litepicker
                    v-model="form.validatefrom"
                    :options="{
                      autoApply: false,
                      showWeekNumbers: true,
                      dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true
                      },
                      format: 'YYYY-MM-DD'
                    }"
                    class="pl-12 form-control"
                  />
                </div>
              </div>
              <div class="mt-6 preview">
                <label>Valid Date To</label>
                <div class="relative">
                  <div
                    class="absolute flex items-center justify-center w-10 h-full text-gray-600 bg-gray-100 border rounded-l dark:bg-dark-1 dark:border-dark-4"
                  >
                    <CalendarIcon class="w-4 h-4" />
                  </div>
                  <Litepicker
                    v-model="form.validateto"
                    :options="{
                      autoApply: false,
                      showWeekNumbers: true,
                      dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true
                      },
                      format: 'YYYY-MM-DD'
                    }"
                    class="pl-12 form-control"
                  />
                </div>
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input Description"
                  v-model="form.desc"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="number"
                  class="w-full form-control"
                  placeholder="Input Spesial Discount"
                  v-model="form.spesialdisc"
                />
              </div>
            </div>
            <div class="px-5 pb-8 mb-12 text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                @click="createVoucher()"
                type="button"
                class="w-24 btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Added Confirmation Modal -->
    <!-- BEGIN: Update Confirmation Modal -->
    <div
      id="update-voucher-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      v-if="modalShow"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-left">
              <div class="text-3xl">Update Voucher</div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input Title"
                  v-model="form.namaKupon"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input Code Voucher"
                  v-model="form.kodeKupon"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input Description"
                  v-model="form.deskripsi"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="number"
                  class="w-full form-control"
                  placeholder="Input Spesial Discount"
                  v-model="form.potongan_spesial"
                />
              </div>
              <div class="mt-6 preview">
                <label>Valid Date From</label>
                <div class="relative">
                  <div
                    class="absolute flex items-center justify-center w-10 h-full text-gray-600 bg-gray-100 border rounded-l dark:bg-dark-1 dark:border-dark-4"
                  >
                    <CalendarIcon class="w-4 h-4" />
                  </div>
                  <Litepicker
                    v-model="form.valid_from"
                    :options="{
                      autoApply: false,
                      showWeekNumbers: true,
                      dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true
                      },
                      format: 'YYYY-MM-DD'
                    }"
                    class="pl-12 form-control"
                  />
                </div>
              </div>
              <div class="mt-6 preview">
                <label>Valid Date To</label>
                <div class="relative">
                  <div
                    class="absolute flex items-center justify-center w-10 h-full text-gray-600 bg-gray-100 border rounded-l dark:bg-dark-1 dark:border-dark-4"
                  >
                    <CalendarIcon class="w-4 h-4" />
                  </div>
                  <Litepicker
                    v-model="form.valid_to"
                    :options="{
                      autoApply: false,
                      showWeekNumbers: true,
                      dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true
                      },
                      format: 'YYYY-MM-DD'
                    }"
                    class="pl-12 form-control"
                  />
                </div>
              </div>
            </div>
            <div class="px-5 pb-8 mb-12 text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                @click="updateVoucher()"
                type="button"
                class="w-24 btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Update Confirmation Modal -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      v-if="modalShow == true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
              <div class="mt-5 text-3xl">Are you sure?</div>
              <div class="mt-2 text-gray-600">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                type="button"
                class="w-24 btn btn-danger"
                @click="deleteVoucher()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>
<script src="./Script.vue" />
