<script>
import ApiService from '../../service/api'
const service = new ApiService()
export default {
  data() {
    return {
      teamsData: [],
      usersData: [],
      usersNotExist: [],
      form: {},
      showModal: true
    }
  },
  mounted() {
    this.loadTeams()
    this.loadUsers()
  },
  methods: {
    loadTeams() {
      try {
        service.serviceGetTeam().then((response) => {
          switch (response.status) {
            case 200:
              this.teamsData = response.data
              break
            default:
              console.log(response)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    loadUsers() {
      try {
        service.serviceGetUsers().then((response) => {
          switch (response.status) {
            case 200:
              this.usersData = response.results
              break
            default:
              console.log(response)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    loadNotExistUsers(data) {
      try {
        this.form = {}
        this.form = data
        service.serviceGetUserNotExistTeam(data.idTeam).then((res) => {
          switch (res.status) {
            case 200:
              this.usersNotExist = res.results
              console.log(this.usersNotExist)
              break
            default:
              console.log(res)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    createTeam() {
      try {
        service.servicePostTeam(this.form).then((response) => {
          switch (response.status) {
            case 200:
              this.loadTeams()
              this.loadUsers()
              this.showModal = false
              location.reload()
              break
            default:
              console.log(response)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    createNewMembers() {
      try {
        service.servicePostNewMembers(this.form).then((res) => {
          console.log(res)
          switch (res.status) {
            case 200:
              this.showModal = false
              location.reload()
              break
            default:
              console.log(res)
              break
          }
          location.reload()
        })
      } catch (error) {
        console.log(error)
      }
    },

    updateTeam() {
      try {
        console.log(this.form)
      } catch (error) {
        console.log(error)
      }
    },

    deleteTeam() {
      try {
        service.serviceDeleteTeam(this.form).then((res) => {
          this.loadTeams()
          this.loadUsers()
          this.showModal = false
          location.reload()
          // switch (res.status) {
          //   case 200:
          //     break
          //   default:
          //     console.log(res)
          //     break
          // }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
