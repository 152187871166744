<template>
  <div>
    <div class="flex items-center mt-8 intro-y">
      <h2 class="mr-auto text-lg font-medium">Profile Layout</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: Profile Menu -->
      <div
        class="
          flex flex-col-reverse
          col-span-12
          lg:col-span-12
          2xl:col-span-12
          lg:block
        "
      >
        <div class="mt-5 intro-y box lg:mt-0">
          <div class="relative flex items-center p-5">
            <div class="w-20 h-20 image-fit">
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                src="https://i.picsum.photos/id/904/200/200.jpg?hmac=QegM9tS4hRwLbLWCb2W91mYYovO_itG2JmSQiz0PnrM"
              />
            </div>
            <div class="ml-4 mr-auto">
              <div class="text-base font-medium">Upload New Avatar</div>
              <div class="flex">
                <button
                  type="button"
                  class="px-2 py-1 mt-2 btn btn-outline-secondary"
                >
                  Choose file.....
                </button>
                <p class="mt-3 ml-5">No file chosen...</p>
              </div>
              <p class="mt-2">The maximum file size allowed is 200KB.</p>
              <div class="mt-3">
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-confirmation-modal"
                >
                  <button
                    type="button"
                    class="px-2 py-1 mt-2 btn btn-outline-danger"
                  >
                    Remove avatar
                  </button>
                </a>

                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#update-password-modal"
                >
                  <button
                    type="button"
                    class="px-2 py-1 mt-2 btn btn-outline-primary ml-3"
                  >
                    Ganti Password
                  </button>
                </a>
              </div>

              <!-- <div class="text-base font-medium">
                {{ auth.Nama }}
              </div> -->
              <!-- <div class="text-gray-600">{{ $f()[0].jobs[0] }}</div> -->
            </div>
          </div>
          <div class="p-5 border-t border-gray-200 dark:border-dark-5">
            <div id="faq-accordion-1" class="accordion">
              <!-- Start Nama -->
              <div class="accordion-item">
                <div id="faq-accordion-content-1" class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-accordion-collapse-1"
                    aria-expanded="true"
                    aria-controls="faq-accordion-collapse-1"
                  >
                    <p
                      class="
                        flex
                        items-center
                        font-medium
                        text-theme-1
                        dark:text-theme-10
                      "
                    >
                      <ActivityIcon class="w-4 h-4 mr-2" /> Nama
                    </p>
                  </button>
                </div>
                <div
                  id="faq-accordion-collapse-1"
                  class="accordion-collapse collapse show"
                  aria-labelledby="faq-accordion-content-1"
                  data-bs-parent="#faq-accordion-1"
                >
                  <div
                    class="
                      leading-relaxed
                      text-gray-700
                      accordion-body
                      dark:text-gray-600
                    "
                  >
                    <input
                      type="text"
                      class="
                        block
                        px-6
                        py-3
                        mt-3
                        border-gray-300
                        intro-x
                        login__input
                        form-control
                      "
                      placeholder="Nama"
                      v-model="auth.nama"
                    />
                  </div>
                </div>
              </div>
              <!-- end nama -->
              <!-- Start useraname -->
              <div class="accordion-item">
                <div id="faq-accordion-content-1" class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-accordion-collapse-1"
                    aria-expanded="true"
                    aria-controls="faq-accordion-collapse-1"
                  >
                    <p
                      class="
                        flex
                        items-center
                        font-medium
                        text-theme-1
                        dark:text-theme-10
                      "
                    >
                      <ActivityIcon class="w-4 h-4 mr-2" /> Username
                    </p>
                  </button>
                </div>
                <div
                  id="faq-accordion-collapse-1"
                  class="accordion-collapse collapse show"
                  aria-labelledby="faq-accordion-content-1"
                  data-bs-parent="#faq-accordion-1"
                >
                  <div
                    class="
                      leading-relaxed
                      text-gray-700
                      accordion-body
                      dark:text-gray-600
                    "
                  >
                    <input
                      type="text"
                      class="
                        block
                        px-6
                        py-3
                        mt-3
                        border-gray-300
                        intro-x
                        login__input
                        form-control
                      "
                      placeholder="Username"
                      v-model="auth.username"
                    />
                  </div>
                </div>
              </div>
              <!-- end useraname -->
              <!-- Start password -->
              <!-- <div class="accordion-item">
                <div id="faq-accordion-content-1" class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-accordion-collapse-1"
                    aria-expanded="true"
                    aria-controls="faq-accordion-collapse-1"
                  >
                    <p
                      class="
                        flex
                        items-center
                        font-medium
                        text-theme-1
                        dark:text-theme-10
                      "
                    >
                      <ActivityIcon class="w-4 h-4 mr-2" /> Password
                    </p>
                  </button>
                </div>
                <div
                  id="faq-accordion-collapse-1"
                  class="accordion-collapse collapse show"
                  aria-labelledby="faq-accordion-content-1"
                  data-bs-parent="#faq-accordion-1"
                >
                  <div
                    class="
                      leading-relaxed
                      text-gray-700
                      accordion-body
                      dark:text-gray-600
                    "
                  >
                    <input
                      type="text"
                      class="
                        block
                        px-6
                        py-3
                        mt-3
                        border-gray-300
                        intro-x
                        login__input
                        form-control
                      "
                      placeholder="Curerent Password"
                      v-model="auth.crpsw"
                    />
                    <input
                      type="text"
                      class="
                        block
                        px-6
                        py-3
                        mt-3
                        border-gray-300
                        intro-x
                        login__input
                        form-control
                      "
                      placeholder="New Password"
                      v-model="auth.newpsw"
                    />
                    <input
                      type="text"
                      class="
                        block
                        px-6
                        py-3
                        mt-3
                        border-gray-300
                        intro-x
                        login__input
                        form-control
                      "
                      placeholder="Password Confirmation"
                      v-model="auth.cfpsw"
                    />
                  </div>
                </div>
              </div> -->
              <!-- end password -->
              <!-- start alamat -->
              <div class="accordion-item">
                <div id="faq-accordion-content-1" class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-accordion-collapse-1"
                    aria-expanded="true"
                    aria-controls="faq-accordion-collapse-1"
                  >
                    <p
                      class="
                        flex
                        items-center
                        font-medium
                        text-theme-1
                        dark:text-theme-10
                      "
                    >
                      <ActivityIcon class="w-4 h-4 mr-2" /> Alamat
                    </p>
                  </button>
                </div>
                <div
                  id="faq-accordion-collapse-1"
                  class="accordion-collapse collapse show"
                  aria-labelledby="faq-accordion-content-1"
                  data-bs-parent="#faq-accordion-1"
                >
                  <div
                    class="
                      leading-relaxed
                      text-gray-700
                      accordion-body
                      dark:text-gray-600
                    "
                  >
                    <textarea
                      class="
                        form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        focus:text-gray-700
                        focus:bg-white
                        focus:border-blue-600
                        focus:outline-none
                        mt-4
                      "
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Input alamat"
                      v-model="auth.alamat"
                    ></textarea>
                  </div>
                </div>
              </div>
              <!-- End Alamat -->
              <!-- Start Jenis Kelamin -->
              <div class="accordion-item">
                <div id="faq-accordion-content-1" class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-accordion-collapse-1"
                    aria-expanded="true"
                    aria-controls="faq-accordion-collapse-1"
                  >
                    <p
                      class="
                        flex
                        items-center
                        font-medium
                        text-theme-1
                        dark:text-theme-10
                      "
                    >
                      <ActivityIcon class="w-4 h-4 mr-2" /> Jenis Kelamin
                    </p>
                  </button>
                </div>
                <div
                  id="faq-accordion-collapse-1"
                  class="accordion-collapse collapse show"
                  aria-labelledby="faq-accordion-content-1"
                  data-bs-parent="#faq-accordion-1"
                >
                  <div
                    class="
                      leading-relaxed
                      text-gray-700
                      accordion-body
                      dark:text-gray-600
                    "
                  >
                    <div class="mt-3 ml-5">
                      <div>
                        <div class="form-check">
                          <input
                            class="
                              float-left
                              w-4
                              h-4
                              mt-1
                              mr-2
                              align-top
                              transition
                              duration-200
                              bg-white bg-center bg-no-repeat bg-contain
                              border border-gray-300
                              rounded-full
                              appearance-none
                              cursor-pointer
                              form-check-input
                              checked:bg-blue-600 checked:border-blue-600
                              focus:outline-none
                            "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value="Pria"
                          />
                          <label
                            class="inline-block text-gray-800 form-check-label"
                            for="flexRadioDefault1"
                          >
                            Pria
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="
                              float-left
                              w-4
                              h-4
                              mt-1
                              mr-2
                              align-top
                              transition
                              duration-200
                              bg-white bg-center bg-no-repeat bg-contain
                              border border-gray-300
                              rounded-full
                              appearance-none
                              cursor-pointer
                              form-check-input
                              checked:bg-blue-600 checked:border-blue-600
                              focus:outline-none
                            "
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            value="Wanita"
                          />
                          <label
                            class="inline-block text-gray-800 form-check-label"
                            for="flexRadioDefault2"
                          >
                            Wanita
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Jenis Kelamin -->
              <!-- Start Tanggal Lahir -->
              <div class="accordion-item">
                <div id="faq-accordion-content-1" class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-accordion-collapse-1"
                    aria-expanded="true"
                    aria-controls="faq-accordion-collapse-1"
                  >
                    <p
                      class="
                        flex
                        items-center
                        font-medium
                        text-theme-1
                        dark:text-theme-10
                      "
                    >
                      <ActivityIcon class="w-4 h-4 mr-2" /> Tanggal Lahir
                    </p>
                  </button>
                </div>
                <div
                  id="faq-accordion-collapse-1"
                  class="accordion-collapse collapse show"
                  aria-labelledby="faq-accordion-content-1"
                  data-bs-parent="#faq-accordion-1"
                >
                  <div
                    class="
                      leading-relaxed
                      text-gray-700
                      accordion-body
                      dark:text-gray-600
                    "
                  >
                    <div class="relative mt-3">
                      <div
                        class="
                          absolute
                          flex
                          items-center
                          justify-center
                          w-10
                          h-full
                          text-gray-600
                          bg-gray-100
                          border
                          rounded-l
                          dark:bg-dark-1 dark:border-dark-4
                        "
                      >
                        <CalendarIcon class="w-4 h-4" />
                      </div>
                      <Litepicker
                        v-model="auth.tanggal_lahir"
                        :options="{
                          autoApply: false,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true
                          },
                          format: 'YYYY-MM-DD'
                        }"
                        class="pl-12 form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- End Tanggal Lahir -->
            </div>
          </div>
          <div
            class="
              flex
              float-right
              p-5
              border-t border-gray-200
              dark:border-dark-5
            "
          >
            <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#update-confirmation-modal"
              ><button type="button" class="px-2 py-1 mr-2 btn btn-primary">
                Update Profile
              </button></a
            >

            <button type="button" class="px-2 py-1 btn btn-outline-secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
      <!-- END: Profile Menu -->
      <!-- BEGIN: Delete Confirmation Modal -->
      <div
        id="delete-confirmation-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog" v-if="showModal">
          <div class="modal-content">
            <div class="p-0 modal-body">
              <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
                <div class="mt-5 text-3xl">Are you sure?</div>
                <div class="mt-2 text-gray-600">
                  Avatar will be removed. Are you sure?.
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 mr-1 btn btn-outline-secondary"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="w-24 btn btn-danger"
                  @click="deleteUser()"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
      <!-- BEGIN: Delete Confirmation Modal -->
      <div
        id="update-confirmation-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog" v-if="showModal">
          <div class="modal-content">
            <div class="p-0 modal-body">
              <div class="p-5 text-center">
                <XCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
                <div class="mt-5 text-3xl">Are you sure update profile?</div>
                <!-- <div class="mt-2 text-gray-600">
                  Avatar will be removed. Are you sure?.
                </div> -->
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 mr-1 btn btn-outline-primary"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="w-24 btn btn-danger"
                  @click="updateProfile()"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
      <!-- BEGIN: Success Confirmation Modal -->
      <div
        id="success-confirmation-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog" v-if="showModal">
          <div class="modal-content">
            <div class="p-0 modal-body">
              <div class="p-5 text-center">
                <CheckCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
                <div class="mt-5 text-3xl">Success Update Profile</div>
                <!-- <div class="mt-2 text-gray-600">
                  Do you really want to delete these records? <br />This process
                  cannot be undone.
                </div> -->
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 mr-1 btn btn-outline-secondary"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="w-24 btn btn-danger"
                  @click="deleteUser()"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Success Confirmation Modal -->
      <!-- BEGIN: Update Password Modal -->
      <div
        id="update-password-modal"
        class="modal"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog" v-if="showModal">
          <div class="modal-content">
            <div class="p-0 modal-body">
              <div class="p-5 text-center">
                <!-- <CheckCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" /> -->
                <div class="mt-5 text-3xl">Ganti Password</div>
                <div
                  class="
                    leading-relaxed
                    text-gray-700
                    accordion-body
                    dark:text-gray-600
                  "
                >
                  <input
                    type="text"
                    class="
                      block
                      px-6
                      py-3
                      mt-3
                      border-gray-300
                      intro-x
                      login__input
                      form-control
                    "
                    placeholder="Curent Password"
                    v-model="form.crpsw"
                  />
                  <input
                    type="text"
                    class="
                      block
                      px-6
                      py-3
                      mt-3
                      border-gray-300
                      intro-x
                      login__input
                      form-control
                    "
                    placeholder="New Password"
                    v-model="form.newpsw"
                  />
                  <input
                    type="text"
                    class="
                      block
                      px-6
                      py-3
                      mt-3
                      border-gray-300
                      intro-x
                      login__input
                      form-control
                    "
                    placeholder="Password Confirmation"
                    v-model="form.cfpsw"
                  />
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 mr-1 btn btn-outline-secondary"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="w-24 btn btn-primary"
                  @click="updatePassword()"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Success Confirmation Modal -->
      <!-- BEGIN: Alert Notification Content -->
      <div id="alert-notification-content" class="toastify-content hidden flex">
        <XCircleIcon class="text-theme-6" />
        <div class="ml-4 mr-4">
          <div class="font-medium">ERROR</div>
          <div class="text-gray-600 mt-1">Password tidak sama</div>
        </div>
      </div>
      <!-- END: Alert Notification Content -->
      <!-- BEGIN: Notification Content -->
      <div
        id="success-notification-content"
        class="toastify-content hidden flex"
      >
        <CheckCircleIcon class="text-theme-9" />
        <div class="ml-4 mr-4">
          <div class="font-medium">Sukses</div>
          <div class="text-gray-600 mt-1">
            {{ pesan }}
          </div>
        </div>
      </div>
      <!-- END: Notification Content -->
    </div>
  </div>
</template>
<script src="./Script.vue" />
