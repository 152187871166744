<script>
import Toastify from 'toastify-js'
import ApiService from '../../service/api'
const service = new ApiService()

export default {
  data() {
    return {
      usersData: [],
      form: {},
      showModal: true,
      alert: {}
    }
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    loadUser() {
      try {
        service.serviceGetUsers().then((response) => {
          switch (response.status) {
            case 200:
              this.usersData = response.results
              break
            case 400:
              console.log(response.message)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    createdUser() {
      try {
        if (this.form.password !== this.form.repeatPassword) {
          const data = {
            status: 'Failed',
            desc: 'Password dan Repeat Password tidak sama'
          }
          this.alert = data
        } else {
          service.servicePostUser(this.form).then((response) => {
            switch (response.status) {
              case 200:
                this.alert.status = 'Success'
                this.alert.desc = 'berhasil menambahkan data user'
                this.loadUser()
                this.showModal = false
                this.toasty('#alert-content')
                break
              default:
                console.log(response.message)
                break
            }
            location.reload()
          })
        }
        this.toasty('#alert-content')
      } catch (error) {
        console.log(this.error)
      }
    },

    updateUser() {
      try {
        service.serviceUpdateUser(this.form).then((res) => {
          switch (res.status) {
            case 200:
              this.showModal = false
              this.loadUser()
              this.alert.status = 'Success'
              this.alert.description = 'berhasil update data user'
              break
            default:
              console.log(res)
              break
          }
          location.reload()
        })
      } catch (error) {
        console.log(error)
      }
    },

    deleteUser() {
      // console.log(this.form.idPerson)
      try {
        service.serviceDeleteUser(this.form.idPerson).then((response) => {
          switch (response.status) {
            case 200:
              this.alertstatus = 'Success'
              this.alertdesc = 'berhasil menghapus data user'
              this.loadUser()
              this.showModal = false
              // location.reload()
              this.toasty('#notification-content')
              break
            default:
              console.log(response.message)
              break
          }
          location.reload()
        })
      } catch (error) {
        console.log(error)
      }
    },
    toasty(data) {
      Toastify({
        node: cash(data).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    }
  }
}
</script>
