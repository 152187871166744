<template>
  <div>
    <h2 class="mt-10 text-lg font-medium intro-y">Teams Layout</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="flex flex-wrap items-center col-span-12 mt-2 intro-y sm:flex-nowrap"
      >
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#added-team-modal"
        >
          <button class="mr-2 shadow-md btn btn-primary">
            Add New Team
          </button></a
        >
        <!-- <div class="dropdown">
          <button
            class="px-2 text-gray-700 dropdown-toggle btn box dark:text-gray-300"
            aria-expanded="false"
          >
            <span class="flex items-center justify-center w-5 h-5">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="w-40 dropdown-menu">
            <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
              >
                <UsersIcon class="w-4 h-4 mr-2" /> Add Group
              </a>
              <a
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
              >
                <MessageCircleIcon class="w-4 h-4 mr-2" /> Send Message
              </a>
            </div>
          </div>
        </div> -->
        <div class="hidden mx-auto text-gray-600 md:block">
          Showing 1 to 10 of 150 entries
        </div>
        <div class="w-full mt-3 sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
          <div class="relative w-56 text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="w-56 pr-10 form-control box placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Users Layout -->
      <div
        v-for="(team, index) in teamsData"
        :key="index"
        class="col-span-12 intro-y md:col-span-6 lg:col-span-4"
      >
        <div class="box">
          <div class="flex items-start px-5 pt-5">
            <div class="flex flex-col items-center w-full lg:flex-row">
              <!-- <div class="w-16 h-16 image-fit">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="require(`@/assets/images/${faker.photos[0]}`).default"
                />
              </div> -->
              <div class="mt-3 text-center lg:ml-4 lg:text-left lg:mt-0">
                <a href="" class="font-medium">{{ team.namaTeam }}</a>
                <!-- <div class="text-gray-600 text-xs mt-0.5">
                  {{ team.namaTeam }}
                </div> -->
              </div>
            </div>
            <div class="absolute top-0 right-0 mt-3 mr-5 dropdown">
              <a
                class="block w-5 h-5 dropdown-toggle"
                href="javascript:;"
                aria-expanded="false"
              >
                <MoreHorizontalIcon
                  class="w-5 h-5 text-gray-600 dark:text-gray-300"
                />
              </a>
              <div class="w-40 dropdown-menu">
                <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#update-team-modal"
                    v-on:click="form = team"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                  >
                    <Edit2Icon class="w-4 h-4 mr-2" /> Edit
                  </a>

                  <a
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                    class="flex items-center block p-2 transition duration-300 ease-in-out bg-white rounded-md dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2"
                    v-on:click="form = team"
                  >
                    <TrashIcon class="w-4 h-4 mr-2" /> Delete
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="p-5 text-center lg:text-left">
            <div>Anggota</div>

            <div
              class="flex items-center justify-center mt-5 text-gray-600 lg:justify-start"
              v-for="(member, index) in team.member"
              :key="index"
              href="javascript:;"
            >
              <UserIcon class="w-3 h-3 mr-2" />
              {{ member.nama }}
            </div>
            <div
              class="flex items-center justify-center mt-1 text-gray-600 lg:justify-start"
            ></div>
          </div>
          <div
            class="p-5 text-center border-t border-gray-200 lg:text-right dark:border-dark-5"
          >
            <a
              href="javascript:;"
              data-toggle="modal"
              data-target="#added-members-modal"
              v-on:click="loadNotExistUsers(team)"
            >
              <button class="px-2 py-1 mr-2 btn btn-primary">
                <PlusIcon class="w-4 h-4" h /></button
            ></a>

            <button class="px-2 py-1 btn btn-outline-secondary">Profile</button>
          </div>
        </div>
      </div>
      <!-- END: Team Layout -->
      <!-- BEGIN: Pagination -->
      <div
        class="flex flex-wrap items-center col-span-12 intro-y sm:flex-row sm:flex-nowrap"
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="">
              <ChevronsLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronLeftIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">1</a>
          </li>
          <li>
            <a class="pagination__link pagination__link--active" href="">2</a>
          </li>
          <li>
            <a class="pagination__link" href="">3</a>
          </li>
          <li>
            <a class="pagination__link" href="">...</a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronRightIcon class="w-4 h-4" />
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <ChevronsRightIcon class="w-4 h-4" />
            </a>
          </li>
        </ul>
        <select class="w-20 mt-3 form-select box sm:mt-0">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
    <!-- BEGIN: Create Team Modal -->
    <div
      id="added-team-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      v-if="showModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <div class="flex items-center intro-y">
                <h2 class="mr-auto text-lg font-medium">Add New Team</h2>
              </div>
              <div class="mt-3 text-left">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input text"
                  v-model="form.team"
                />
              </div>
              <div class="mt-3 text-left">
                <TomSelect
                  id="crud-form-2"
                  v-model="form.user"
                  class="w-full"
                  multiple
                >
                  <option
                    v-for="(user, index) in usersData"
                    :key="index"
                    :value="user.idPerson"
                  >
                    {{ user.username }}
                  </option>
                </TomSelect>
              </div>
            </div>
            <div class="px-5 pb-8 text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                type="button"
                class="w-24 btn btn-primary"
                @click="createTeam()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Create Team Modal -->
    <!-- BEGIN: Create New Member Modal -->
    <div
      id="added-members-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      v-if="showModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <div class="flex items-center intro-y">
                <h2 class="mr-auto text-lg font-medium">Add New Members</h2>
              </div>
              <div class="mt-3 text-left">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input text"
                  v-model="form.namaTeam"
                />
              </div>
              <div class="mt-3 text-left">
                <TomSelect
                  id="crud-form-2"
                  v-model="form.user"
                  class="w-full"
                  multiple
                >
                  <option
                    v-for="(user, index) in usersNotExist"
                    :key="index"
                    :value="user.idPerson"
                  >
                    {{ user.nama }}
                  </option>
                </TomSelect>
              </div>
            </div>
            <div class="px-5 pb-8 text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                type="button"
                class="w-24 btn btn-primary"
                @click="createNewMembers()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Create New Member  Modal -->
    <!-- BEGIN: Update New Member Modal -->
    <div
      id="update-team-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      v-if="showModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <div class="flex items-center intro-y">
                <h2 class="mr-auto text-lg font-medium">Edit Team</h2>
              </div>
              <div class="mt-3 text-left">
                <label>Nama Team</label>
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input text"
                  v-model="form.namaTeam"
                />
              </div>
              <div class="mt-3 text-left">
                <!-- <TomSelect
                  id="crud-form-2"
                  v-model="form.users"
                  class="w-full"
                  multiple
                >
                  <option
                    v-for="(member, index) in form.member"
                    :key="index"
                    :value="member.idPerson"
                  >
                    {{ member.nama }}
                  </option>
                </TomSelect> -->
                <div>
                  <label>Member Team</label>
                  <div
                    class="mt-2 form-check"
                    v-for="(member, index) in form.member"
                    :key="index"
                  >
                    <input
                      id="checkbox-switch-1"
                      class="form-check-input"
                      type="checkbox"
                      v-bind:value="member.idPerson"
                      v-model="form.users"
                    />
                    <label class="form-check-label" for="checkbox-switch-1">
                      {{ member.nama }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="px-5 pb-8 text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                type="button"
                class="w-24 btn btn-primary"
                @click="updateTeam()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Update New Member  Modal -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      v-if="showModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
              <div class="mt-5 text-3xl">Are you sure?</div>
              <div class="mt-2 text-gray-600">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                type="button"
                class="w-24 btn btn-danger"
                @click="deleteTeam(team)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script src="./Script.vue" />
