import axios from 'axios'
// import sha256 from 'sha256'

// url end point API
// const apiURL = 'https://kalenderkonten.com/app-dashboard'
// const apiURL = 'https://kalenderkonten.com/app-mrboba'
// const apiURL = 'https://kalenderkonten.com/app-crofflesuka'
// const apiURL = 'https://kalenderkonten.com/app-dashboard'
const apiURL = 'https://kalenderkonten.com/app-akgkosmetika'

// // url API payment getway sandbox
// const apiKey = 'F9A7E286-033B-4FB2-B3DB-B3639AA7A0B9'
// const vaKey = '1179002168422225'
// // let urlDirect = 'https://sandbox.ipaymu.com/api/v2/payment/direct'
// const urlReDirect = 'https://sandbox.ipaymu.com/api/v2/payment'

// //  url invoice
// const returnUrl = 'https://invoice.kalenderkonten.com/payment'
// const notifyUrl = 'https://kalenderkonten.com/app-dashboard/pemberitahuan'
// const cancelUrl = 'https://kalenderkonten.com/app-dashboard/batal'

export default class ApiService {
  // Begin method user
  async serviceGetUsers() {
    const result = await axios.get(apiURL + '/person')
    return result.data
  }

  async servicePostUser(data) {
    console.log(data)
    console.log(data.gender)
    const result = await axios.post(apiURL + '/person', {
      username: data.username,
      nama: data.name,
      gender: data.gender,
      alamat: data.alamat,
      level: 'pengguna',
      password: data.password,
      tanggalLahir: data.date
    })
    return result.data
  }

  async serviceUpdateUser(data) {
    console.log(data)
    const result = await axios.put(apiURL + '/person/update/' + data.idPerson, {
      nama: data.nama,
      username: data.username,
      gender: data.gender,
      alamat: data.alamat,
      tanggalLahir: data.tanggalLahir,
      created_at: data.created_at
    })
    return result.data
  }

  async serviceDeleteUser(id) {
    const result = await axios.delete(apiURL + '/person/delete/' + id)
    return result.data
  }
  // End method user

  // ====================================================================================================

  // Begin method team
  async serviceGetTeam() {
    const result = await axios.get(apiURL + '/team')
    return result.data
  }

  async serviceGetUserNotExistTeam(data) {
    const results = await axios.get(apiURL + '/team/users/' + data)
    return results.data
  }

  async servicePostTeam(data) {
    if (data.user.length == 0) {
      const result = await axios.post(apiURL + '/team', {
        namaTeam: data.team
      })
      return result.data
    } else {
      const result = await axios.post(apiURL + '/team', {
        namaTeam: data.team,
        member: data.user
      })
      return result.data
    }
  }

  async serviceUpdateTeam() {
    const result = await axios.put(apiURL + '/team/')
    return result.data
  }

  async serviceDeleteTeam(data) {
    if (data.member.length == 0) {
      const result = await axios.delete(apiURL + '/team/delete/' + data.idTeam)
      return result.data
    } else {
      const listMember = []
      data.member.forEach(element => {
        listMember.push(element.idMember)
      })
      const result = await axios.delete(
        apiURL + '/team/delete/' + data.idTeam,
        {
          member: listMember
        }
      )
      return result.data
    }
  }

  async servicePostNewMembers(data) {
    const results = await axios.post(apiURL + '/datamember', {
      idTeam: data.idTeam,
      member: data.user
    })
    return results.data
  }

  // End method team

  // ====================================================================================================

  // Begin  category
  async serviceGetCategory() {
    const result = await axios.get(apiURL + '/category')
    return result.data
  }

  async servicePostCategory(form) {
    const result = await axios.post(apiURL + '/category', {
      category: form.category
    })
    return result.data
  }

  async serviceUpdateCategory(data) {
    const result = await axios.put(
      apiURL + '/category/update/' + data.idCategory,
      {
        category: data.category,
        updated_at: data.updated_at
      }
    )
    return result.data
  }

  async serviceDeleteCategory(id) {
    console.log(id)
    const result = await axios.delete(apiURL + '/category/delete/' + id)
    return result.data
  }

  // End method category

  // ====================================================================================================

  //  Begin method login

  async serviceLogin(data) {
    const results = await axios.post(apiURL + '/admin/login', {
      username: data.username,
      password: data.password
    })
    return results
  }

  async serviceProfile(data) {
    const results = await axios.get(apiURL + `/admin/show/${data}`)
    return results
  }

  async serviceUpdateProfile(data) {
    if (!data.newpsw && !data.cfpsw && !data.crpsw) {
      const results = await axios.put(
        apiURL + `/admin/update/${data.idPerson}`,
        {
          nama: data.nama,
          gender: data.gender,
          alamat: data.alamat,
          tanggal_lahir: data.tanggal_lahir,
          username: data.username,
          created_at: data.created_at
        }
      )
      return results
    } else {
      // const results = await axios.put(apiURL + 'admin/update/' + data.userid, {})
      // return results
      // console.log('update data use password')
      // console.log(data)
    }
  }

  async serviceUpdatePasword(data, id) {
    const results = axios.post(apiURL + '/admin/ubahpassword/' + id, {
      currentPassword: data.crpsw,
      newPassword: data.newpsw
    })
    return results
  }

  //  End method login

  // ====================================================================================================

  //  Begin method customer register product

  async serviceGetOrder() {
    const result = await axios.get(apiURL + '/registerpaket')
    return result.data
  }

  async serviceRegisterProduct(data) {
    let harpot = 0
    let harvat = 0
    if (data.paket.harga == 950000) {
      harpot = 0
      harvat = data.paket.harga * (10 / 100)
    } else if (data.paket.harga == 1500000) {
      harpot = data.paket.harga * (10 / 100)
      harvat = data.paket.harga * (10 / 100)
    } else if (data.paket.harga == 3500000) {
      harpot = data.paket.harga * (20 / 100)
      harvat = data.paket.harga * (10 / 100)
    }
    this.paymentIPaymu(data)
    const results = await axios.post(apiURL + '/registerpaket/simpan', {
      nama: data.nama,
      noWA: data.noWA,
      akunIG: data.idIG,
      email: data.email,
      nama_paket: data.paket.title,
      durasi: data.nama == 'STARTUP' ? 1 : data.nama == 'UMKM' ? 3 : 12,
      harga: data.paket.harga,
      hargaPotongan: harpot,
      disc: data.nama == 'STARTUP' ? 0 : data.nama == 'UMKM' ? 10 : 20,
      vat: 10,
      hargaVat: harvat
    })
    // this.paymentIPaymu(results.data)
    return results.data
  }

  // paymentIPaymu(data) {
  //   console.log(data)
  //   const body = {
  //     account: vaKey,
  //     buyerName: data.nama,
  //     buyerEmail: data.email,
  //     buyerPhone: '+62' + data.noWA,
  //     price: [data.paket.harga],
  //     description: [data.paket.title],
  //     referenceId: data.results,
  //     expired: '24',
  //     expiredType: 'hours',
  //     qty: ['1'],
  //     product: [data.paket.title],
  //     returnUrl: returnUrl,
  //     notifyUrl: notifyUrl,
  //     cancelUrl: cancelUrl
  //   }
  //   const bodyEncrypt = sha256(JSON.stringify(body))
  //   const stringtosign = 'POST:' + vaKey + ':' + bodyEncrypt + ':' + apiKey
  //   const signature = this.$CryptoJS.enc.Hex.stringify(
  //     this.$CryptoJS.HmacSHA256(stringtosign, apiKey)
  //   )
  //   HmacSHA256()

  //   const config = {
  //     method: 'POST',
  //     url: urlReDirect,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       signature: signature,
  //       va: vaKey,
  //       timestamp: Date.now()
  //     },
  //     data: body
  //   }

  //   console.log(body)
  //   console.log(bodyEncrypt)
  //   console.log(stringtosign)
  //   console.log(signature)
  //   console.log(config)

  //   // axios(config)
  //   //   .then(res => {
  //   //     console.log(res)
  //   //     window.open(res.data.Data.Url, '_parent')
  //   //   })
  //   //   .catch(function(err) {
  //   //     console.log(err)
  //   //   })
  // }

  async serviceDeleteRegPaket(data) {
    const results = await axios.delete(apiURL + '/registerpaket/delete/' + data)
    return results.data
  }
  //  End method customer register product

  // ====================================================================================================

  //  Begin method Opsi Bayar

  async serviceOpsi() {
    const result = await axios.get(apiURL + '/opsibayar')
    return result.data
  }

  //  End method Opsi Bayar

  // ====================================================================================================

  //  Begin method Voucher

  async serviceVoucher() {
    const result = await axios.get(apiURL + '/kupon')
    return result.data
  }

  async servicePostVoucher(data) {
    const result = await axios.post(apiURL + '/kupon', {
      namaKupon: data.title,
      kodeKupon: data.code,
      deskripsi: data.desc,
      potspesial: data.spesialdisc,
      valid_from: data.validatefrom,
      valid_to: data.validateto
    })
    return result.data
  }

  async serviceUpdateVocuher(data) {
    const results = await axios.post(apiURL + '/kupon/update/' + data.idKupon, {
      namaKupon: data.namaKupon,
      kodeKupon: data.kodeKupon,
      deskripsi: data.deskripsi,
      potongan_spesial: data.potongan_spesial,
      valid_from: data.valid_from,
      valid_to: data.valid_to,
      created_at: data.created_at
    })
    return results.data
  }

  async serviceDeleteVoucher(data) {
    const results = await axios.delete(apiURL + '/kupon/' + data)
    return results.data
  }

  //  End method Voucher

  // ====================================================================================================

  //  Begin method Paket

  async servicePaket() {
    const result = await axios.get(apiURL + '/paket/layanan')
    return result.data
  }

  async servicePostPaket(data) {
    console.log(data)
    // const result = await axios.post(apiURL + '/paket', {
    //   nama: data.nama,
    //   disc: data.disc,
    //   discount: data.discount,
    //   vat: data.vat,
    //   lamaLangganan: data.lamaLangganan
    // })
    // return result.data
  }

  async servicePutPaket(data) {
    const results = await axios.post(
      apiURL + `/paket/update/${data.idPaketOptions}`,
      {
        nama: data.nama,
        disc: data.disc,
        discount: data.discount,
        lamaLangganan: data.lamaLangganan,
        created_at: data.updated_at
      }
    )
    return results
  }

  async serviceDeletePaket(data) {
    // console.log(data)
    const results = await axios.delete(apiURL + '/paket/delete/' + data)
    return results.data
  }

  //  End method Paket

  // ====================================================================================================

  //  Begin method Email
  async serviceGetEmail() {
    const results = await axios.get(apiURL + '/emailtemplate/1')
    return results.data
  }

  async servicePostEmail(data) {
    // console.log(data.nama)
    // console.log(data.email)
    // console.log(data.akunIG)
    // console.log(data.nama_paket)
    const from = 'dev.suzuya@gmail.com'
    const to = 'raihan.andikaenginer31@gmail.com'
    const subject = 'Checking mail'
    const result = await axios.post(apiURL + '/sendmail', {
      from: from,
      to: to,
      subject: subject
    })
    return result.data
  }

  async servicePutEmail() {}

  async serviceDeleteEmail() {}

  //  End method Email
  /**
   * begin event
   */
  async serviceGetEvent() {
    const result = await axios.get(apiURL + '/kegiatan')
    return result.data
  }

  async serviceApproveEvent(id, status) {
    const result = await axios.put(apiURL + '/kegiatan/' + id, {
      status: status
    })
    return result.data
  }

  async serviceDeleteEvent(id) {
    const result = await axios.delete(apiURL + '/kegiatan/delete/' + id)
    return result.data
  }

  async serviceSaveEvent(url, form) {
    const result = await axios.post(apiURL + url, form)
    return result.data
  }

  /**
   * end event
   */
  /**
   * chat
   */
  serviceGet(id) {
    return axios.get(apiURL + '/diskusi/show/' + id).then(result => {
      return result.data
    })
  }

  ambilpesan(id) {
    return axios.get(apiURL + '/diskusi/ambil/' + id).then(result => {
      return result.data
    })
  }

  saveMessage(message, id) {
    return axios.put(apiURL + '/diskusi/update/' + id, message).then(result => {
      return result.data
    })
  }
}
