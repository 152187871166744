<script>
import router from '@/router'
import Toast from '@/utils/toster'
import { defineComponent, ref, provide } from 'vue'
import ApiService from '../../service/api'
const service = new ApiService()
const peringatan = new Toast()
export default defineComponent({
  setup() {
    const announcementRef = ref()
    const newProjectsRef = ref()
    const todaySchedulesRef = ref()

    provide('bind[announcementRef]', (el) => {
      announcementRef.value = el
    })

    provide('bind[newProjectsRef]', (el) => {
      newProjectsRef.value = el
    })

    provide('bind[todaySchedulesRef]', (el) => {
      todaySchedulesRef.value = el
    })

    const prevAnnouncement = () => {
      const el = announcementRef.value
      el.tns.goTo('prev')
    }
    const nextAnnouncement = () => {
      const el = announcementRef.value
      el.tns.goTo('next')
    }
    const prevNewProjects = () => {
      const el = newProjectsRef.value
      el.tns.goTo('prev')
    }
    const nextNewProjects = () => {
      const el = newProjectsRef.value
      el.tns.goTo('next')
    }
    const prevTodaySchedules = () => {
      const el = todaySchedulesRef.value
      el.tns.goTo('prev')
    }
    const nextTodaySchedules = () => {
      const el = todaySchedulesRef.value
      el.tns.goTo('next')
    }

    return {
      prevAnnouncement,
      nextAnnouncement,
      prevNewProjects,
      nextNewProjects,
      prevTodaySchedules,
      nextTodaySchedules
    }
  },
  data() {
    return {
      form: {},
      auth: [],
      showModal: true,
      pesan: {
        status: '',
        msg: ''
      }
    }
  },
  mounted() {
    this.loaddata()
  },
  methods: {
    loaddata() {
      const data = localStorage.getItem('AUTH')
      this.auth = JSON.parse(data)
      // console.log(this.auth.userid)
    },
    updateProfile() {
      try {
        service.serviceUpdateProfile(this.auth).then((res) => {
          switch (res.status) {
            case 200:
              this.showModal = false
              localStorage.clear()
              router.push({ path: '/' })
              break
            default:
              console.log(res)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    updatePassword() {
      try {
        console.log(this.form)
        if (this.form.newpsw !== this.form.cfpsw) {
          this.pesan.status = 'ERROR'
          this.pesan.msg = 'passwor tidak sama'
          peringatan.alert()
        } else {
          service
            .serviceUpdatePasword(this.form, this.auth.idPerson)
            .then((res) => {
              console.log(res)
              switch (res.data.status) {
                case 200:
                  this.showModal = false
                  this.pesan.status = 'Success'
                  this.pesan.msg = 'Berhasil ubah passsword'
                  peringatan.berhasil()
                  location.reload()
                  break
                default:
                  this.pesan = 'Gagal ubah password'
                  peringatan.gagal()
                  break
              }
            })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
})
</script>
