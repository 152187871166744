<template>
  <div>
    <h2 class="mt-10 text-lg font-medium intro-y">Paket</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="
          flex flex-wrap
          items-center
          col-span-12
          mt-2
          intro-y
          sm:flex-nowrap
        "
      >
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#added-paket-modal"
        >
          <button class="mr-2 shadow-md btn btn-primary">
            Add New Paket
          </button></a
        >

        <div class="dropdown">
          <button
            class="
              px-2
              text-gray-700
              dropdown-toggle
              btn
              box
              dark:text-gray-300
            "
            aria-expanded="false"
          >
            <span class="flex items-center justify-center w-5 h-5">
              <PlusIcon class="w-4 h-4" />
            </span>
          </button>
          <div class="w-40 dropdown-menu">
            <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
              <a
                href=""
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  rounded-md
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                "
              >
                <UsersIcon class="w-4 h-4 mr-2" /> Add Group
              </a>
              <a
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  rounded-md
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                "
              >
                <MessageCircleIcon class="w-4 h-4 mr-2" /> Send Message
              </a>
            </div>
          </div>
        </div>
        <div class="hidden mx-auto text-gray-600 md:block">
          Showing 1 to 10 of 150 entries
        </div>
        <div class="w-full mt-3 sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
          <div class="relative w-56 text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="w-56 pr-10 form-control box placeholder-theme-13"
              placeholder="Search..."
            />
            <SearchIcon
              class="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Paket -->
      <div
        v-for="(paket, index) in paket"
        :key="index"
        class="col-span-12 intro-y md:col-span-6"
      >
        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div class="report-box zoom-in">
            <div class="p-5 box">
              <div class="flex">
                <ArchiveIcon class="report-box__icon text-theme-10" />
                <div class="ml-auto mr-6" v-if="paket.harga == 1500000">
                  <Tippy
                    tag="div"
                    class="cursor-pointer report-box__indicator bg-theme-9"
                  >
                    10 %
                    <ChevronUpIcon class="w-4 h-4 ml-0.5" />
                  </Tippy>
                </div>
                <div class="ml-auto mr-6" v-else-if="paket.harga == 3500000">
                  <Tippy
                    tag="div"
                    class="cursor-pointer report-box__indicator bg-theme-9"
                  >
                    20 %
                    <ChevronUpIcon class="w-4 h-4 ml-0.5" />
                  </Tippy>
                </div>
                <div class="ml-auto mr-6" v-else-if="paket.harga == 950000">
                  <!-- <Tippy
                    tag="div"
                    class="cursor-pointer report-box__indicator bg-theme-9"
                  >
                    20 %
                    <ChevronUpIcon class="w-4 h-4 ml-0.5" />
                  </Tippy> -->
                </div>
              </div>
              <div class="mt-6 text-3xl font-medium leading-8 text-center">
                Paket {{ paket.title }}
              </div>
              <!-- <div class="mt-1 text-base text-center text-gray-600">
                {{ paket.nama }}
              </div> -->
              <div class="mt-1 text-base text-gray-600">
                vat : 10
              </div>
              <div class="flex">
                <div class="absolute top-0 right-0 mt-3 mr-5 dropdown">
                  <a
                    class="block w-5 h-5 mt-2 dropdown-toggle"
                    href="javascript:;"
                    aria-expanded="false"
                  >
                    <MoreVerticalIcon
                      class="w-5 h-5 text-gray-600 dark:text-gray-300"
                    />
                  </a>
                  <div class="w-40 dropdown-menu">
                    <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
                      <a
                        class="
                          flex
                          items-center
                          block
                          p-2
                          transition
                          duration-300
                          ease-in-out
                          bg-white
                          rounded-md
                          dark:bg-dark-1
                          hover:bg-gray-200
                          dark:hover:bg-dark-2
                        "
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#update-paket-modal"
                        v-on:click="editForm = paket"
                      >
                        <Edit2Icon class="w-4 h-4 mr-2" /> Edit
                      </a>
                      <a
                        class="
                          flex
                          items-center
                          block
                          p-2
                          transition
                          duration-300
                          ease-in-out
                          bg-white
                          rounded-md
                          dark:bg-dark-1
                          hover:bg-gray-200
                          dark:hover:bg-dark-2
                        "
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#delete-confirmation-modal"
                        v-on:click="form = paket"
                      >
                        <TrashIcon class="w-4 h-4 mr-2" />
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="mt-6 text-3xl font-medium leading-8">
                {{ cat.category }}
              </div>
              <div class="mt-1 text-base text-gray-600">Item Sales</div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- END: Category -->
      <div class="absolute top-0 right-0 mt-3 mr-5 dropdown">
        <a
          class="block w-5 h-5 dropdown-toggle"
          href="javascript:;"
          aria-expanded="false"
        >
          <MoreHorizontalIcon
            class="w-5 h-5 text-gray-600 dark:text-gray-300"
          />
        </a>
        <div class="w-40 dropdown-menu">
          <div class="p-2 dropdown-menu__content box dark:bg-dark-1">
            <a
              href=""
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                bg-white
                rounded-md
                dark:bg-dark-1
                hover:bg-gray-200
                dark:hover:bg-dark-2
              "
            >
              <Edit2Icon class="w-4 h-4 mr-2" /> Edit
            </a>
            <a
              href=""
              class="
                flex
                items-center
                block
                p-2
                transition
                duration-300
                ease-in-out
                bg-white
                rounded-md
                dark:bg-dark-1
                hover:bg-gray-200
                dark:hover:bg-dark-2
              "
            >
              <TrashIcon class="w-4 h-4 mr-2" /> Delete
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: added paket Modal -->
    <div id="added-paket-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-left">
              <div class="text-3xl">Add Paket</div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input nama paket"
                  v-model="form.nama"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="number"
                  class="w-full form-control"
                  placeholder="Input Harga Paket"
                  v-model="form.disc"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="number"
                  class="w-full form-control"
                  placeholder="Input Diskon Paket"
                  v-model="form.discount"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="number"
                  class="w-full form-control"
                  placeholder="Input Pajak Paket"
                  v-model="form.vat"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input Lama Langganan Paket"
                  v-model="form.lamaLangganan"
                />
              </div>
            </div>
            <div class="px-5 pb-8 text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                @click="createPaket()"
                type="button"
                class="w-24 btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: added paket Modal -->
    <!-- BEGIN: update paket Modal -->
    <div
      id="update-paket-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
      v-if="modalForm == true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-left">
              <div class="text-3xl">Update Paket</div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input nama paket"
                  v-model="editForm.nama"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="number"
                  class="w-full form-control"
                  placeholder="Input Harga Paket"
                  v-model="editForm.disc"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="number"
                  class="w-full form-control"
                  placeholder="Input Diskon Paket"
                  v-model="editForm.discount"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="number"
                  class="w-full form-control"
                  placeholder="Input Pajak Paket"
                  v-model="editForm.vat"
                />
              </div>
              <div class="mt-6">
                <input
                  id="crud-form-1"
                  type="text"
                  class="w-full form-control"
                  placeholder="Input Lama Langganan Paket"
                  v-model="editForm.lamaLangganan"
                />
              </div>
            </div>
            <div class="px-5 pb-8 text-right">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <a
                href="javascript:;"
                data-toggle="modal"
                data-target="#update-confirmation-modal"
                ><button
                  type="button"
                  class="w-24 btn btn-primary"
                  @click="action()"
                >
                  Update
                </button></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: update paket Modal -->
    <!-- BEGIN: update Confirmation Modal -->
    <div
      id="update-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
              <div class="mt-5 text-3xl">Are you sure?</div>
              <div class="mt-2 text-gray-600">
                Do you really want to update these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                type="button"
                class="w-24 btn btn-danger"
                @click="updatePaket()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: update Confirmation Modal -->
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="p-0 modal-body">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 mx-auto mt-3 text-theme-6" />
              <div class="mt-5 text-3xl">Are you sure?</div>
              <div class="mt-2 text-gray-600">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="w-24 mr-1 btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                type="button"
                class="w-24 btn btn-danger"
                @click="deletePaket()"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>
<script src="./Script.vue" />
