<script>
import ApiService from '../../service/api'
const service = new ApiService()
export default {
  data() {
    return {
      paket: [],
      form: {},
      editForm: {},
      modalForm: true,
      modalAlert: true
    }
  },
  mounted() {
    this.loadPaket()
  },
  methods: {
    loadPaket() {
      try {
        service.servicePaket().then((response) => {
          switch (response.status) {
            case 200:
              console.log(response.results)
              this.paket = response.results
              console.log(this.paket)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    createPaket() {
      try {
        service.servicePostPaket(this.form).then((res) => {
          switch (res.status) {
            case 200:
              this.loadPaket()
              this.modalForm = false
              break
            default:
              console.log(res)
              break
          }
          location.reload()
        })
      } catch (error) {
        console.log(error)
      }
    },

    updatePaket() {
      try {
        service.servicePutPaket(this.editForm).then((res) => {
          switch (res.status) {
            case 200:
              this.loadPaket()
              this.modalAlert = false
              break
            default:
              console.log(res)
              break
          }
          location.reload()
        })
      } catch (error) {
        console.log(error)
      }
    },

    deletePaket() {
      try {
        service.serviceDeletePaket(this.form.idPaketOptions).then((res) => {
          switch (res.status) {
            case 200:
              this.loadPaket()
              this.modalForm = false
              this.modalAlert = false
              break
            default:
              console.log(res)
              break
          }
          location.reload()
        })
      } catch (error) {
        console.log(error)
      }
    },
    action() {
      console.log('modal form false')
      this.modalForm = false
    }
  }
}
</script>
