<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="flex items-center pt-5 -intro-x">
            <img
              alt="Kalenderkonten logo"
              src="@/assets/images/logo-white.png"
            />
          </a>
          <div class="my-auto">
            <div
              class="
                mt-10
                text-4xl
                font-medium
                leading-tight
                text-white
                -intro-x
              "
            >
              Ready-Made Material for <br />
              Your Social Media And Marketing.
            </div>
            <div
              class="
                mt-5
                text-lg text-white
                -intro-x
                text-opacity-70
                dark:text-gray-500
              "
            >
              Membantu bisnis Anda bertumbuh Lebih Cepat
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="
              my-auto
              mx-auto
              xl:ml-20
              bg-white
              dark:bg-dark-1
              xl:bg-transparent
              px-5
              sm:px-8
              py-8
              xl:p-0
              rounded-md
              shadow-md
              xl:shadow-none
              w-full
              sm:w-3/4
              lg:w-2/4
              xl:w-auto
            "
          >
            <div class="mx-auto sm:w-3/4 lg:w-2/4">
              <img
                class="object-top xl:hidden 2xl:hidden lg:hidden"
                alt="Kalenderkonten logo"
                src="@/assets/images/logo-red.png"
              />
            </div>
            <h2
              class="
                intro-x
                font-bold
                text-2xl
                xl:text-3xl
                text-center
                xl:text-left
              "
            >
              Sign In
            </h2>
            <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div>
            <form class="validate-form" @submit.prevent="submitHandler">
              <div class="intro-x mt-8">
                <div class="input-form">
                  <input
                    type="text"
                    class="
                      intro-x
                      login__input
                      form-control
                      py-3
                      px-4
                      border-gray-300
                      block
                      form-control
                    "
                    name="username"
                    v-model.trim="validasi.username.$model"
                    :class="{ 'border-theme-6': validasi.username.$error }"
                    placeholder="Username"
                  />
                  <template v-if="validasi.username.$error">
                    <div
                      v-for="(error, index) in validasi.username.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
                <div class="input-form">
                  <input
                    type="password"
                    class="
                      intro-x
                      login__input
                      form-control
                      py-3
                      px-4
                      border-gray-300
                      block
                      mt-4
                      form-control
                    "
                    name="password"
                    :class="{ 'border-theme-6': validasi.password.$error }"
                    v-model.trim="validasi.password.$model"
                    placeholder="Password"
                  />
                  <template v-if="validasi.password.$error">
                    <div
                      v-for="(error, index) in validasi.password.$errors"
                      :key="index"
                      class="text-theme-6 mt-2"
                    >
                      {{ error.$message }}
                    </div>
                  </template>
                </div>
              </div>
              <!-- <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >Remember me</label
                >
              </div>
              <a href="">Forgot Password?</a>
            </div> -->
              <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                <button
                  class="
                    btn btn-primary
                    py-3
                    px-4
                    w-full
                    xl:w-32 xl:mr-3
                    align-top
                  "
                  type="submit"
                >
                  Login
                </button>
                <!-- <button
                class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top"
              >
                Sign up
              </button> -->
              </div>
            </form>
            <!-- <div
              class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
            >
              By signin up, you agree to our <br />
              <a class="text-theme-1 dark:text-theme-10" href=""
                >Terms and Conditions</a
              >
              &
              <a class="text-theme-1 dark:text-theme-10" href=""
                >Privacy Policy</a
              >
            </div> -->
          </div>
        </div>
        <!-- END: Login Form -->
        <!-- BEGIN: Notification Content -->
        <div
          id="error-notification-content"
          class="toastify-content hidden flex"
        >
          <XCircleIcon class="text-theme-6" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Error</div>
            <div class="text-gray-600 mt-1">{{ pesan }}</div>
          </div>
        </div>

        <div
          id="success-notification-content"
          class="toastify-content hidden flex"
        >
          <CheckCircleIcon class="text-theme-9" />
          <div class="ml-4 mr-4">
            <div class="font-medium">Sukses</div>
            <div class="text-gray-600 mt-1">
              {{ pesan }}
            </div>
          </div>
        </div>
        <!-- END: Notification Content -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import {
  required,
  minLength,
  maxLength
  // url
  // email,
  // integer
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import ApiService from '../../service/api'
import Toast from '../../utils/toster'
const service = new ApiService()
const peringatan = new Toast()

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })
    const initialState = {
      username: '',
      password: ''
    }
    const formData = reactive({ ...initialState })
    const rules = {
      username: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(255)
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(255)
      }
    }
    const validasi = useVuelidate(rules, toRefs(formData))
    const save = () => {
      validasi.value.$touch()
      if (validasi.value.$invalid) {
        peringatan.gagal()
      }
    }
    return {
      validasi,
      formData,
      rules,
      initialState,
      save
    }
  },
  data: () => ({
    loading: false,
    passwordShow: false,
    pesan: '',
    auth: []
  }),
  created() {
    var vm = this
    this.auth = JSON.parse(localStorage.getItem('AUTH'))
    if (this.auth && this.auth !== null) {
      vm.$router.push({ path: '/home' })
    }
  },
  methods: {
    submitHandler() {
      this.save()
      const data = useVuelidate(this.rules, toRefs(this.formData))
      if (!data.value.$invalid) {
        this.loading = true
        service
          .serviceLogin(this.formData)
          .then((response) => {
            this.loading = false
            // console.log(response)
            switch (response.data.status) {
              case 200:
                Object.assign(this.formData, this.initialState)
                this.pesan = 'Login berhasil, anda akan di arahkan ke dashboard'
                setTimeout(function () {
                  peringatan.berhasil()
                }, 500)
                var vm = this
                localStorage.setItem('AUTH', JSON.stringify(response.data.data))
                setTimeout(function () {
                  vm.$router.push({ path: '/home' })
                }, 3000)
                //  write code go to other page ex -> Home page or Dashboard Page
                break
              default:
                this.pesan = 'Username / password anda salah'
                setTimeout(function () {
                  peringatan.gagal()
                }, 500)
                break
            }
          })
          .catch(() => {
            this.pesan =
              'Terjadi kesalahan saat login, periksa koneksi internet'
            setTimeout(function () {
              peringatan.gagal()
            }, 500)
            this.loading = false
          })
      }
    }
  }
})
</script>
