<script>
import ApiService from '../../service/api'
const service = new ApiService()
export default {
  data() {
    return {
      voucher: [],
      form: {},
      modalShow: true,
      modalSuccess: null
    }
  },
  mounted() {
    this.loadvoucher()
  },
  methods: {
    loadvoucher() {
      try {
        service.serviceVoucher().then((response) => {
          switch (response.status) {
            case 200:
              this.voucher = response.results
              // console.table(this.voucher)
              break
            case 400:
              console.log(response.message)
              break
            default:
              console.log('Error')
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    createVoucher() {
      try {
        service.servicePostVoucher(this.form).then((response) => {
          switch (response.status) {
            case 200:
              this.loadvoucher()
              this.modalShow = false
              break
            default:
              console.log(response.message)
              break
          }
          location.reload()
        })
      } catch (error) {
        console.log(error)
      }
    },

    updateVoucher() {
      try {
        service.serviceUpdateVocuher(this.form).then((res) => {
          switch (res.status) {
            case 200:
              this.modalShow = false
              location.reload()
              break
            default:
              console.log(res)
              break
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    deleteVoucher() {
      try {
        service.serviceDeleteVoucher(this.form.idKupon).then((res) => {
          switch (res.status) {
            case 200:
              this.loadvoucher()
              this.modalShow = false
              break
            default:
              break
          }
          location.reload()
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
